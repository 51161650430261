:root {
  --theme-bg-1: black;
  --theme-bg-2: #151515;
  --theme-bg-3: #2d2d2d;
  --theme-color-1: #abaaaa;
  --theme-color-disabled: #555;
  --theme-highlight-1: gold;
  --theme-warning-1: #F44336;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  display: grid;
  place-items: center;
  align-content: center;
  background-color: var(--theme-bg-1);
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  padding-left: 0px;
  margin: 0;
}

li {
  list-style-type: none;
}

code, textarea {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}